var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tamaño"},[_c('v-container',[_c('h1',{staticClass:"color2--text d-flex justify-center text-center titulo mt-6"},[_vm._v(" Galeria De Fotos Dinámica ")])]),_c('v-sheet',{staticClass:"mx-auto d-flex justify-center black"},[_c('v-slide-group',{class:{'carrete1':_vm.mover,'carrete2': !_vm.mover},attrs:{"show-arrows":"","center-active":""},scopedSlots:_vm._u([{key:"prev",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"icono izquierda","color":"black","tile":""},on:{"click":function($event){_vm.mover=!_vm.mover}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"color2"}},[_vm._v(" mdi-arrow-left-circle-outline ")])],1)]}},{key:"next",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"aria-label":"icono derecha","color":"black","tile":""},on:{"click":function($event){_vm.mover=!_vm.mover}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"color2"}},[_vm._v(" mdi-arrow-right-circle-outline ")])],1)]}}])},_vm._l((_vm.cuadros),function(item,index){return _c('v-slide-item',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var toggle = ref.toggle;
return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"ma-4",attrs:{"height":"100","color":"black","width":"200","to":{name:'arte', params: {page: item.id}}},on:{"click":toggle}},[_c('v-img',{staticClass:"sombra",attrs:{"alt":item.id,"aspect-ratio":"2","src":item.foto},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('comcargando',{attrs:{"circulo":"color2"}})]},proxy:true}],null,true)},[_c('v-expand-transition',[(hover)?_c('div',{staticClass:"d-flex transition-fast-in-fast-out color2 darken-2 \n                                v-card--reveal button white--text",staticStyle:{"height":"100%"}},[_vm._v(" "+_vm._s(item.titulo)+" ")]):_vm._e()])],1),_c('v-card-text',{staticClass:"py-8 botonEntrar text-capitalize text-caption \n                        text-sm-subtitle-1 text-md-h6 text-lg-h4 text-xl-h3"},[_vm._v(" "+_vm._s(item.titulo)+" ")])],1)]}}],null,true)})]}}],null,true)})}),1)],1),_c('comCuadro',{attrs:{"cuadros":_vm.cuadros}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }